import { Injectable } from "@angular/core";
import { ConfigurationService } from "proceduralsystem-clientcomponents";
import { BehaviorSubject } from "rxjs";
import { COOKIE_LANGUAGE } from "../constants/shared.constants";

@Injectable({
    providedIn: 'root'
})
export class LocaleService {
    public lngPreference$ = new BehaviorSubject<string>('en');

    set lngPreference(lng: string) {
        this.lngPreference$.next(lng);
        this.configurationService.setCookie(COOKIE_LANGUAGE, lng, 30);
    }

    get storedLngPrefence(): string {
        return this.configurationService.getCookie(COOKIE_LANGUAGE);
    }

    constructor(private readonly configurationService: ConfigurationService<any>) { }
}
