import { Component, Input, OnInit } from '@angular/core';
import { ApplicationsEnum, ApplicationStatusString } from '../home.enum';
import { ApplicationStatus } from '../home.model';

@Component({
  selector: 'app-members-portal-access-card',
  templateUrl: './members-portal-access-card.component.html',
  styleUrls: ['./members-portal-access-card.component.css']
})
export class MembersPortalAccessCardComponent {

  @Input() membersPortalStatus: ApplicationStatus;
  @Input() emailDomainInvalid: boolean;
  applicationsEnum = ApplicationsEnum;
  applicationStatus = ApplicationStatusString;
  membersPortalSystemUrl = '';

  public get aisLinnSystemUrl(): string {
    if (this.membersPortalStatus) {
      this.membersPortalSystemUrl = `https://${this.membersPortalStatus.systemUri}`;
    }

    return this.membersPortalSystemUrl;
  }

}
