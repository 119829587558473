import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  HostListener,
  ViewChild,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { LanguageNames } from '../../constants/general.constant';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oir-language-toggle',
  templateUrl: './language-toggle.component.html',
  styleUrls: ['./language-toggle.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageToggleComponent {

  @ViewChild('mobileLangSelectPanel', { static: true }) mobileLangSelectPanel: ElementRef;
  public languageNames = LanguageNames;
  public lngToggleValue = { en: 'Eng', ga: 'Ga' };
  public panelClosed = true;

  @Output() lngUpdate: EventEmitter<string> = new EventEmitter();
  @Input() language = LanguageNames.en;

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (
      !this.panelClosed &&
      !this.mobileLangSelectPanel.nativeElement.contains(event.target)
    ) {
      this.panelClosed = true;
      this.cd.detectChanges();
    }
  }

  constructor(
    private readonly cd: ChangeDetectorRef,
    public readonly internationalisationService: TranslateService
  ) {}

  public toggleLang(lang: string, isMobile = false): void {
    this.lngUpdate.emit(lang);
    if (isMobile) {
      this.panelClosed = true;
    }
    this.cd.detectChanges();
  }
}
