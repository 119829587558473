export enum ApplicationsEnum {
  DocumentsLaid = 1,
  MembersPortal = 2
}

export enum ApplicationStatusString {
  Pending = 'Pending',
  Approved = 'Approved',
  Denied = 'Denied'
}
