export const environment = {
  production: false,
  api: {
    commsApiUrl: "",
    referenceData: "referencedata",
    applicationStatus: "applicationstatus",
    requestAccess: "requestaccess",
    userProfile: "userprofile",
  },
};
