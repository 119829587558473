import { Component, Input } from '@angular/core';
import { NotificationsTrayComponent } from 'proceduralsystem-clientcomponents';

@Component({
  selector: 'oir-extended-notification-tray',
  templateUrl: './extended-notification-tray.component.html',
  styleUrls: ['./extended-notification-tray.component.less']
})
export class ExtendedNotificationTrayComponent extends NotificationsTrayComponent {
  @Input() lang;
  replaceWithQuotes(data: string) {
    return data.replace(/&quot;/g, '"');
  }
}
