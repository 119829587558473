import { GenericObjectModel } from '../models/shared.model';

export enum USER_ROLE {
    CommitteeSubmitterUser = '40'
}

export enum LanguageNames {
    ga = 'ga',
    en = 'en'
}

export enum DOCUMENT_STATUS {
    Laid = 1,
    Error,
    Deleted,
    ToBeRemoved,
    Recalled
}

export const COOKIE_LANGUAGE: string = 'lang';

export const TIME_FORMAT_EN = 'hh:mm A DD MMMM YYYY';
export const TIME_FORMAT_GA = 'HH:mm DD/MM/YYYY';

export const VIEW_MODE: GenericObjectModel = {
    NEW: 'NEW',
    VIEW: 'VIEW'
};

export enum ATTACHMENT_STATUS {
    NEW = 'New',
    SCANNING = 'Scanning',
    SAFE = 'Safe',
    INFECTED = 'Infected'
}

export enum ATTACHMENT_EXPORT_STATUS {
    New = 1,
    ReadyExport,
    InProgressExport,
    Completed,
    FailedExport,
    ReadyUpdate,
    InProgressUpdate,
    FailedUpdate,
}

export enum ATTACHMENT_TYPE {
    SUBMITTED = 'submitted',
    UPLOADED = 'uploaded'
}

export const ATTACHMENT_VALUE_NAMES = {
    fileReference: 'fileReference',
    fileName: 'fileName'
};

export enum NOTIFICATION_TYPES {
    MESSAGES = 'MessageNotification',
    RELAY_DOCUMENT = 'ErrorNotification',
    LAID_DOCUMENT = 'DocumentLaidNotification',
}
