export enum LanguageNames {
    ga = 'ga',
    en = 'en'
}

export const DEFAULT_COOKIE_EXPIRATION = 30;
export const COOKIE_LANGUAGE = 'lang';
export const REDIRECT_COOKIE_LANGUAGE = 'loginLang';

export enum REQUEST_STATUS_KEY {
    'Approved' = 'FORM.LABELS.STATUS_APPROVED',
    'Denied' = 'FORM.LABELS.STATUS.DENIED',
    'Pre-Approved' = 'FORM.LABELS.STATUS.PRE_APPROVED',
    'Pending' = 'FORM.LABELS.STATUS.PENDING',
}
