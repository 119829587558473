import { OnInit, Component } from "@angular/core";
import { ConfigurationService, NavigationService } from "proceduralsystem-clientcomponents";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AppConfig, AppConfigService } from "./shared/services/app-config.service";
import { LocaleService } from "./shared/services/locale.service";
import { SignalRService } from "./shared/services/signalR.service";
import { TranslateService } from "@ngx-translate/core";
import { COOKIE_LANGUAGE } from "./shared/constants/shared.constants";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly broadcastService: MsalBroadcastService,
    private readonly msalService: MsalService,
    private readonly appConfigService: AppConfigService,
    private readonly signalRService: SignalRService,
    private readonly configurationService: ConfigurationService<AppConfig>,
    readonly localeService: LocaleService,
    readonly internationalisationService: TranslateService
  ) {}

  ngOnInit() {
    if (this.configurationService.getCookie(COOKIE_LANGUAGE)) {
      this.toggleLng(this.configurationService.getCookie(COOKIE_LANGUAGE));
    }
    localStorage.clear();
    if (this.msalService.instance.getActiveAccount() === null) {
      this.msalService.loginRedirect({
        scopes: this.appConfigService.getValue("CommsEndpoint").scopes,
      });
    }
    this.initNavigation();
    this.signalRService.createConnection();
  }

  initNavigation() {
    let tree = [];
    tree.push({ title: this.internationalisationService.instant('NAVIGATION.HOME'), path: "home" });

    this.navigationService.init({
      module: { title: this.internationalisationService.instant("NAVIGATION.MY_ACCOUNT"), path: "" },
      tree,
    });
  }

  toggleLng(locale: string) {
      this.internationalisationService.use(locale);
      this.localeService.lngPreference = locale;
      this.initNavigation();
  }
}
