import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigurationService, OirAuthService } from 'proceduralsystem-clientcomponents';
import { ActionInProgressService } from '../services/action-in-progress.service';
import { AppConfig, AppConfigService } from '../services/app-config.service';

@Injectable({ providedIn: 'root' })
export class UserAuthInterceptor implements HttpInterceptor {

  constructor(
    private configurationService: ConfigurationService<AppConfig>,
    private actionInProgressService: ActionInProgressService,
    private readonly config: AppConfigService,
    private readonly oirAuthService: OirAuthService
  ) { }

  /**
   * Interceptor intercepts every outgoing/incoming http request
   * @param req
   * @param next
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userAuth: any = {
      userGuid: this.config.getValue('UserId'),
      userEmail: this.configurationService.getValue('UserName'),
    };

    const userInfoReq: any = req.clone({
      headers: req.headers.append(
        'userAuth', JSON.stringify(userAuth))
    });

    this.actionInProgressService.actionStarted();

    return next.handle(userInfoReq);
  }
}

