import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { OirAuthService, ToastNotificationsService } from 'proceduralsystem-clientcomponents';
import { MsalService } from '@azure/msal-angular';
import { LocaleService } from './locale.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public data: Subject<string>;
  private connection: signalR.HubConnection;

  constructor(
    private readonly configurationService: AppConfigService,
    private readonly authService: OirAuthService,
    private toastNotificationsService: ToastNotificationsService,
    private readonly msalService: MsalService,
    private localeService: LocaleService
  ) {}

  ngOnDestroy(): void {
    this.stopConnection();
  }

  /**
   * Create connection to signalR.
   */
  public createConnection(attemptCount = 0): void {
    const maxAttempts = 30;
    const retryDelay = 60000; // 1 minute
    if (!this.authService.getUsername()) {
      if (attemptCount >= maxAttempts) {
        return;
      }
  
      // Retry after the specified delay
      setTimeout(() => this.createConnection(attemptCount + 1), retryDelay);
      return;
    }
  
    this.authService.getAccessTokenByEndpoint('CommsEndpoint')
      .pipe(
        take(1),
        map(token => ({ signalrUrl: this.configurationService.get('SignalRConnectionUrl'), token }))
      )
      .subscribe(({ signalrUrl, token }) => {
        signalrUrl.pipe(
          take(1)
        ).subscribe(url => {
          // Add token to headers to authorize connection
          const headers = {
            Authorization: `Bearer ${token}`
          };
          const userName = this.configurationService.getValue('UserName');
          this.connection = new signalR.HubConnectionBuilder()
            .withUrl(`${url}?userName=${userName}`, { transport: signalR.HttpTransportType.LongPolling, headers })
            .withAutomaticReconnect()
            .build();
          this.startConnection();
        });
      });
  }

  /**
   * Stop connection to signalR and complete data stream
   * to unsubscribe from it.
   */
  public async stopConnection(): Promise<void> {
    this.connection
      .stop()
      .catch(err => console.log('error while stopping connection', err));

      while (!this.data) {
        await new Promise(resolve => setTimeout(resolve, 100)); // Wait for 100 milliseconds before checking again
      }
    this.data.complete();
  }

  /**
   * Start connection to signalR and subscribe
   * to Notify event to get notifications from server.
   */
  private startConnection(): void {
    this.connection
      .start()
      .catch(err => console.log('error while starting connection', err));
    this.connection.on('Notify', (updated: any) => {
      try {
        const lang = this.localeService.storedLngPrefence;
        this.toastNotificationsService.addNotification({
          title: lang === 'ga' ? updated.irishTitle : updated.title,
          description: lang === 'ga' ? updated.irishDescription : updated.description,
        });
      } catch (error) {
        this.toastNotificationsService.addNotification({
          title: 'COMMON.REQUESTERROR',
          description: 'COMMON.REQUESTERRORDESCRIPTION'
        });
        if (error.status === 401) {
          this.renewToken();
        }
      }

      this.data.next(updated);
    });
  }

  /**
   * Renew access token. This method is called when the access token
   * is expired or the server returns a 401 Unauthorized response.
   */
  renewToken() {
    this.stopConnection();
    this.msalService.acquireTokenSilent({
      scopes: [this.configurationService.getValue('CommsEndpoint').scopes[0]],
    })
    .subscribe(() => {},
    (error) => {
      console.log('Error renewing access token:', error);
    });
  }
}
