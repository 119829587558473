import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
  ApplicationStatus,
  ReferenceData,
  RequestAccessModel,
  UserInfo
} from './home.model';
import { ApplicationsEnum } from './home.enum';
import { AppConfigService } from '../shared/services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    private readonly appConfigService: AppConfigService,
  ) {}

  getReferenceData(): Observable<ReferenceData> {
    const url = `${this.appConfigService.getValue('ApiEndpoint').url}/${environment.api.referenceData}`;

    return this.http.get<ReferenceData>(url);
  }

  getApplicationStatus(userId: string): Observable<Array<ApplicationStatus>> {
    const url = `${this.appConfigService.getValue('ApiEndpoint').url}/${environment.api.applicationStatus}/${userId}`;
    
    return this.http.get<Array<ApplicationStatus>>(url);
  }

  requestAccess(model: RequestAccessModel): Observable<ApplicationStatus> {
    let url = `${this.appConfigService.getValue('ApiEndpoint').url}/${environment.api.requestAccess}`;

    if (model.appId === ApplicationsEnum.DocumentsLaid) {
      url = `${url}/documentslaid`;
    }

    return this.http.post<ApplicationStatus>(url, model, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  updateUserProfile(model: UserInfo): Observable<boolean> {
    const url = `${this.appConfigService.getValue('ApiEndpoint').url}/${environment.api.userProfile}`;

    return this.http.post<boolean>(url, model, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
}
